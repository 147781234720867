import * as React from "react";

import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as QueryUtils from "QueryUtils";
import * as StrictUtils from "Utils/StrictUtils";
import * as StyleUtils from "Utils/StyleUtils";
import * as SubtraceEvent from "ApiContracts/subtrace/event/event";
import * as Verify from "Utils/Verify";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import { DateDisplayTimeZone } from "DateDisplayTimeZone";
import { ExternalLink } from "DesignComponents/ExternalLink";
import { GridColumnDefinition } from "GridColumnDefinition";
import { InternalLink } from "DesignComponents/InternalLink";
import { LazyGrid } from "LazyGrid";
import { QueryResult } from "QueryResult";
import { RequestGraph } from "RequestGraph";
import { Spinner } from "DesignComponents/Spinner";
import { User } from "User";

function getRandomLanguage(seed: number): string {
  const choices: Record<string, number> = {
    "for Python apps": 0.5,
    "for Node.js servers": 0.3,
    "for Go backends": 0.2,
  };

  let total = 0;
  for (const [choice, weight] of Object.entries(choices)) {
    if (total + weight >= seed) {
      return choice;
    }
    total += weight;
  }

  throw new Error("unreachable");
}

function TitleText(): React.ReactElement {
  const seed = React.useState<number>(Math.random())[0];
  const texts: string[] = ["for Kubernetes pods", `${getRandomLanguage(seed)}`, "for virtual machines", "for Docker containers", "that just works"];

  const [textIndex, setTextIndex] = React.useState<number>(0);
  const [textFade, setTextFade] = React.useState<string>("opacity-100 translate-y-[0rem]");

  React.useEffect(() => {
    let interval: number;
    if (textIndex !== texts.length - 1) {
      interval = setInterval(() => {
        setTextFade("translate-y-[1rem] opacity-0 blur-[1rem]");
        setTimeout(() => {
          setTextFade("-translate-y-[1rem] opacity-0 blur-[1rem]");
          setTimeout(() => {
            setTextFade("");
            setTextIndex((textIndex + 1) % texts.length);
          }, 75);
        }, 75);
      }, /* milliseconds */ 3000);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [textIndex, texts.length]);

  return (
    <div className="mt-20 flex justify-center">
      <h2 className="font-semibold text-6xl max-w-3xl flex flex-col items-center p-4 text-zinc-100">
        <span>Automatic tracing</span>
        <span className={`mt-2 transition-all duration-[0.05s] ease-in-out ${textFade}`}>
          <span>{texts[textIndex]}</span>
        </span>
      </h2>
    </div>
  );
}

function PolkaDots(props: { color: string }): React.ReactElement {
  return (
    <div
      style={{
        backgroundImage: `radial-gradient(#ffffff0d 10%, transparent 10%), radial-gradient(#ffffff0d 10%, transparent 10%)`,
        backgroundPosition: "0px 0px, 8px 8px",
        backgroundSize: "16px 16px",
      }}
      className={`pointer-events-none overflow-hidden absolute left-0 top-0 w-full h-full ${props.color}`}
    />
  );
}

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Logo(props: { name: string; src: string }): React.ReactElement {
    return (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img
            className="absolute w-full transition ease-in-out contrast-[0.50] saturation-[2.00] group-hover:saturation-[3.00] grayscale brightness-[0.75] group-hover:brightness-[1.25]"
            src={props.src}
            alt={props.name}
          />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]"
          style={{
            background: "radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Logo name="Node.js" src={NodejsLogo} />
          <Logo name="Go" src={GoLogo} />
          <Logo name="Ruby" src={RubyLogo} />
          <Logo name="Java" src={JavaLogo} />
          <Logo name="PHP" src={PhpLogo} />
          <Logo name="Rust" src={RustLogo} />
          <Logo name="Python" src={PythonLogo} />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Logo name="Docker" src={DockerLogo} />
          <Logo name="Kubernetes" src={KubernetesLogo} />
          <Logo name="AWS" src={AWSLogo} />
          <Logo name="Azure" src={AzureLogo} />
          <Logo name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  // Hardcoding these values so that we don't need to wait for the import of the mock data
  const graphGlobalLeftTimestamp: number = 1729719796000;
  const graphGlobalRightTimestamp: number = 1729719916000;

  const [landingPageMockData, setLandingPageMockData] = React.useState<typeof import("LandingPageMockData") | undefined>(undefined);
  React.useEffect(function loadMockData(): void {
    import(/* webpackChunkName: "mock-data" */ "LandingPageMockData").then((landingPageMockData) => {
      setLandingPageMockData(landingPageMockData);
    });
  });

  const currentUser: User | undefined = CustomHooks.useCurrentUser();

  const [graphFocusedLeftTimestamp, setGraphFocusedLeftTimestamp] = React.useState<number>(graphGlobalLeftTimestamp);
  const [graphFocusedRightTimestamp, setGraphFocusedRightTimestamp] = React.useState<number>(graphGlobalRightTimestamp);

  const columnDefinitions: GridColumnDefinition[] =
    QueryUtils.GRID_QUERY_DEFAULT_COLUMNS.map((field) => SubtraceEvent.knownFieldsToJSON(field)).map((columnName) => ({ columnName })) ?? [];

  return (
    <div className="w-full h-vh flex flex-col">
      <div className="flex justify-center mt-12">
        <div className="w-full max-w-6xl py-2">
          <div className="float-left my-auto flex items-center space-x-4">
            <InternalLink className="px-4 py-2 mr-4 text-md tracking-widest font-mono text-white" label="subtrace" target="/" />
            <ExternalLink className="px-4 py-2 text-sm" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="px-4 py-2 text-sm" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
          </div>
          {renderTopRightButtons()}
        </div>
      </div>

      <TitleText />

      <div className="mt-8 flex justify-center">
        <div className="text-zinc-300 max-w-md text-center">Subtrace is the easiest way to add tracing to your infra. Works out of the box. Built for developers.</div>
      </div>

      <div className="mt-16 flex justify-center">
        <InternalLink className="px-4 py-2" label="Try for free" target="/login" showArrow showAsButton />
      </div>

      <div className="w-full max-w-7xl flex flex-row self-center">
        <div className="mt-60 flex flex-col w-full">{renderProductDemo()}</div>
      </div>

      <div className="max-w-7xl flex flex-row self-center">
        <div className="mt-60 flex flex-col">
          <span className="text-white text-center text-5xl">Ready, set, trace.</span>
          <span className="mt-2 text-center text-zinc-500">Setting up Subtrace to work with your application is effortless.</span>
          <div className="mt-10 mb-5">{renderDiffView()}</div>
          <span className="text-white text-center">Yes, that&apos;s all it takes to get started with Subtrace.</span>
          <span className="text-center text-zinc-500">
            <span>For full setup instructions, read our docs </span>
            <ExternalLink label="here" openInNewTab target="https://docs.subtrace.dev" />
            <span>.</span>
          </span>
        </div>
      </div>

      <div className="mt-60 flex justify-center">
        <div className="flex flex-col space-y-8">
          <div className="flex space-x-8 items-center text-xl font-medium">
            <div className="relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Works the same everywhere.</span>
              <span> </span>
              <span className="text-zinc-400">Every language. Every cloud.</span>
              <Integrations />
            </div>
          </div>

          <div className="flex space-x-8 items-center text-lg font-medium">
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Bring your own storage.</span>
              <span> </span>
              <span className="text-zinc-400">Regain control over your company&apos;s data security. Your tracing data stays in your own servers.</span>
            </div>
            <div className="relative overflow-hidden w-96 h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Zero config.</span>
              <span> </span>
              <span className="text-zinc-400">Subtrace fits into your existing backend out of the box. No code changes required.</span>
            </div>
          </div>

          <div className="flex space-x-8 items-center text-sm">
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Made for startups.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Spend more time on what&apos;s important for your product.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Move fast, search things.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Subtrace is built on ClickHouse, the fastest database for analytics.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Fixed pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Get a predictable bill every month. No surprises.</span>
            </div>
            <div className="relative overflow-hidden w-44 h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <PolkaDots color="bg-zinc-800/20" />
              <span className="font-medium text-zinc-200">Open source.</span>
              <span> </span>
              <ExternalLink className="font-normal text-zinc-300/95" label="Star us on GitHub." target="https://github.com/subtrace/subtrace" />
            </div>
          </div>

          <div className="w-full h-24 flex px-6 py-3 bg-zinc-900 rounded-xl">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="text-xl font-medium flex items-center text-zinc-200">
                <span className="text-zinc-200">Interested?</span>&nbsp;
                <span className="text-zinc-500">Try for free.</span>
              </div>
              <InternalLink className="px-4 py-2" label="Get started" target="/login" showAsButton showArrow />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 flex justify-center">
        <div className="w-full max-w-3xl py-2 mt-16 mb-32">
          <div className="flex items-center space-x-8">
            <span className="text-[13px] pointer-events-none tracking-wide rounded font-semibold text-zinc-600 font-mono mr-4">subtrace</span>
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Discord" openInNewTab target="https://discord.gg/GXuwMJXGUk" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="GitHub" openInNewTab target="https://github.com/subtrace/subtrace" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Support" target="mailto:support@subtrace.dev" />
          </div>
        </div>
      </div>
    </div>
  );

  function getQueryResult(): QueryResult | undefined {
    if (!landingPageMockData) {
      return undefined;
    }

    return {
      ...landingPageMockData.GridQueryResult,
      data: landingPageMockData.GridQueryResult.data.filter((row) => {
        let dateString: string = StrictUtils.ensureDefined(row[SubtraceEvent.knownFieldsToJSON(SubtraceEvent.KnownFields.time)]);
        dateString = dateString.endsWith("Z") ? dateString : dateString + "Z";
        const timestamp: number = new Date(dateString).valueOf();
        return timestamp >= graphFocusedLeftTimestamp && timestamp <= graphFocusedRightTimestamp;
      }),
    };
  }

  function onRequestGraphFocusedTimeRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphFocusedLeftTimestamp(startTimestamp);
    setGraphFocusedRightTimestamp(endTimestamp);
  }

  function renderDiffSummary(): React.ReactNode {
    return (
      <div className="flex flex-row items-center space-x-1">
        <span className="text-[#3fb950] font-semibold">+4</span>
        <span className="text-[#f85149] font-semibold">-2</span>
        <div className="flex flex-row space-x-px">
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[repeating-linear-gradient(to_right_bottom,_#fff9,_#fff9_.11rem,_#da3633_.11rem,_#da3633_.22rem)] bg-[#da3633] border rounded-sm border-[#da3633]" />
          <div className="h-2 w-2 bg-[#656c7633] border rounded-sm border-[#3d444d]" />
        </div>
      </div>
    );
  }

  function renderDiffView(): React.ReactNode {
    return (
      <div className="border border-[#3d444d] rounded-md flex flex-col relative">
        <div className="w-full h-10 bg-[#151b23] border-[#3d444d] border-b flex flex-row justify-between text-white text-xs font-mono items-center rounded-t-md px-3">
          <span>control.Dockerfile</span>
          {renderDiffSummary()}
        </div>
        <div className="w-full h-full grid grid-cols-2 font-mono bg-[#0d1117]">
          <div className="border-[#3d444d] border-r h-full flex flex-col">
            {renderDiffViewLeftSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
          <div className="h-full flex flex-col">
            {renderDiffViewRightSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
        </div>
      </div>
    );
  }

  function renderDiffViewLeftSide(): React.ReactNode {
    return (
      <table className="text-xs w-full">
        <tbody>
          {renderDiffRow(
            7,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> --mount=type=cache,target=/go/pkg --mount=type=cache,target=/root/.cache make -C control</span>
            </span>,
          )}
          {renderDiffRow(8, DiffLineKind.Unchanged, null)}
          {renderDiffRow(
            9,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            10,
            DiffLineKind.Removed,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> apt-get update && apt-get install -y ca-certificates</span>
            </span>,
          )}
          {renderDiffRow(
            11,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> --from=build /go/src/monorepo/control/control /usr/local/bin/control</span>
            </span>,
          )}
          {renderDiffRow(
            12,
            DiffLineKind.Removed,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;node&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;./server.js&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffViewRightSide(): React.ReactNode {
    return (
      <table className="text-xs w-full table-fixed">
        <tbody>
          {renderDiffRow(
            7,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> --mount=type=cache,target=/go/pkg --mount=type=cache,target=/root/.cache make -C control</span>
            </span>,
          )}
          {renderDiffRow(8, DiffLineKind.Unchanged, null)}
          {renderDiffRow(
            9,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            10,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> apt-get update && apt-get install -y ca-certificates</span>
              <span className="bg-[#2ea04366] rounded-sm"> curl</span>
            </span>,
          )}

          {renderDiffRow(
            11,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> --from=build /go/src/monorepo/control/control /usr/local/bin/control</span>
            </span>,
          )}
          {renderDiffRow(
            12,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> curl -sL https://subtrace.dev/install.sh | bash -</span>
            </span>,
          )}
          {renderDiffRow(
            13,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;subtrace&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;run&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;--&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;node&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;./server.js&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffRow(lineNumber: number, diffLineKind: DiffLineKind, text: React.ReactNode): React.ReactNode {
    let diffColumnText: string;
    let lineNumberBackgroundColor: string, diffBackgroundColor: string, lineNumberTextColor: string;

    if (diffLineKind === DiffLineKind.Added) {
      diffColumnText = "+";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#3fb9504d]";
      diffBackgroundColor = "bg-[#2ea04326]";
    } else if (diffLineKind === DiffLineKind.Removed) {
      diffColumnText = "-";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#f851494d]";
      diffBackgroundColor = "bg-[#f8514926]";
    } else if (diffLineKind === DiffLineKind.Unchanged) {
      diffColumnText = "";
      lineNumberTextColor = "text-[#9198a1]";
      lineNumberBackgroundColor = "";
      diffBackgroundColor = "";
    } else {
      Verify.isNever(diffLineKind);
    }

    return (
      <tr className="h-6">
        <td className={`${lineNumberTextColor} text-center w-10 p-0 ${lineNumberBackgroundColor} select-none`}>{lineNumber}</td>
        <td className={`text-white text-center w-6 p-0 ${diffBackgroundColor} select-none`}>{diffColumnText}</td>
        <td className={`text-white ${diffBackgroundColor}`}>{text}</td>
      </tr>
    );
  }

  function renderProductDemo(): React.ReactNode {
    return (
      <div className="flex flex-col space-y-10">
        <span className="text-white text-center text-5xl">Look under the hood of your app.</span>
        <RequestGraph
          countByTimestamp={landingPageMockData?.CountByTimestamp ?? new Map()}
          displayTimeZone={DateDisplayTimeZone.CurrentTimeZone}
          focusedLeftTimestamp={graphFocusedLeftTimestamp}
          focusedRightTimestamp={graphFocusedRightTimestamp}
          globalLeftTimestamp={graphGlobalLeftTimestamp}
          globalRightTimestamp={graphGlobalRightTimestamp}
          isLoading={landingPageMockData == null}
          keepGlobalRangeFixed={true}
          onFocusedTimeRangeChanged={onRequestGraphFocusedTimeRangeChanged}
          onGlobalTimeRangeChanged={() => {} /* This does not happen in this instance of the request graph */}
        />
        <LazyGrid
          fallback={
            <div className="h-[400px] flex justify-center items-center">
              <Spinner className="scale-[200%]" />
            </div>
          }
          className="w-full h-[400px] mt-6"
          columnDefinitions={columnDefinitions}
          headerHeight={45}
          isLoading={landingPageMockData == null}
          queryResult={getQueryResult()}
          rowHeight={35}
        />
      </div>
    );
  }

  function renderTopRightButtons(): React.ReactElement | null {
    if (!currentUser) {
      return (
        <div className="float-right my-auto flex items-center space-x-4">
          <InternalLink className="px-4 py-2" label="Try for free" target="/login" />
        </div>
      );
    }

    return (
      <div className="float-right my-auto flex items-center space-x-4">
        {currentUser.isLoggedIn ? (
          <InternalLink className="px-4 py-2" label="Dashboard" showArrow showAsButton target="/dashboard" />
        ) : (
          <React.Fragment>
            <ExternalLink className="px-4 py-2" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" />
            <InternalLink className="px-4 py-2" label="Try for free" target="/login" showArrow showAsButton />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const enum DiffLineKind {
  Added = "Added",
  Removed = "Removed",
  Unchanged = "Unchanged",
}
