import * as React from "react";

import * as EventLogger from "EventLogger";
import { SubtraceEventKind } from "SubtraceEventKind";

export class AppErrorBoundary extends React.Component<React.PropsWithChildren> {
  public readonly state: AppErrorBoundaryState;

  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(error: Error): Partial<AppErrorBoundaryState> {
    return { error };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    EventLogger.logEvent(SubtraceEventKind.UncaughtAppBoundaryError, {
      component_stack: info.componentStack ?? "",
      digest: info.digest ?? "",
      error_message: error.message,
      error_name: error.name,
      error_stack: error.stack ?? "",
    });
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      return (
        <div className="absolute inset-0 bg-[#0077d9] text-white font-segoe pt-[10%] px-[20%]">
          <div className="flex flex-col relative space-y-6">
            <span className="text-9xl">:(</span>
            <div className="text-3xl">
              Subtrace ran into a problem. You can refresh the page to see if that fixes the issue. If not, reach out to support@subtrace.dev and include the following details:
            </div>
            <div className="whitespace-pre-line">{this.state.error.stack}</div>
          </div>
        </div>
      );
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

interface AppErrorBoundaryState {
  error: Error | undefined;
}
