import * as React from "react";
import * as ReactDomClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import * as Api from "ApiContracts/control/api/api";
import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as NamespaceUtils from "NamespaceUtils";
import * as OrgUtils from "OrgUtils";
import * as SubtraceTagsBase from "SubtraceTagsBase";
import { AppErrorBoundary } from "AppErrorBoundary";
import { Deferred } from "Deferred";
import { DialogContext } from "DialogContext";
import { DialogManager } from "DialogManager";
import { LandingPage } from "LandingPage";
import { LoginPage } from "./LoginPage";
import { OnboardingPage } from "./OnboardingPage";
import { NamespaceContext } from "NamespaceContext";
import { NamespaceManager } from "NamespaceManager";
import { NamespacesPage } from "NamespacesPage";
import { Navigation } from "Navigation";
import { Overlay } from "Overlay";
import { RequestsPage } from "RequestsPage";
import { TokensPage } from "TokensPage";
import { User } from "User";
import { UsersPage } from "UsersPage";

import "./style.css";
import { OrgContext } from "OrgContext";

const rootElement: Element = document.getElementById("root")!;
ReactDomClient.createRoot(rootElement).render(<App />);

const parseUrlRegex: RegExp = new RegExp(/\/dashboard\/(?<namespace>[\w-]*)\/(?<rest>.*)/);

function App(): React.ReactNode {
  const [dialogState, setDialogState] = React.useState<DialogState>({ isDialogOpen: false });
  let deferred: Deferred<any>;

  const dialogManager: DialogManager = {
    show: (dialogComponent: React.ReactNode) => {
      deferred = new Deferred();

      setDialogState({
        isDialogOpen: true,
        dialogComponent,
      });

      return deferred.promise;
    },
    hide: (result: any) => {
      setDialogState({ isDialogOpen: false });
      deferred.resolve(result);
    },
  };

  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [org, setOrg] = React.useState<Api.ListOrgs_Item | undefined>(undefined);
  const [namespaces, setNamespaces] = React.useState<Api.ListNamespaces_Item[] | undefined>(undefined);
  const [currentNamespace, setCurrentNamespace] = React.useState<Api.ListNamespaces_Item | undefined>(undefined);

  React.useEffect(() => {
    async function initialize(): Promise<void> {
      if (currentUser?.isLoggedIn && window.location.pathname !== "/onboarding") {
        SubtraceTagsBase.commonTags.user_id = currentUser.userId;

        const controller: AbortController = new AbortController();
        const { signal } = controller;

        const orgs: Api.ListOrgs_Item[] = await OrgUtils.listOrgs(signal);
        if (orgs.length === 0) {
          document.location = "/onboarding";
          return;
        }

        setOrg(orgs[0]);
        const orgId: string = orgs[0].orgId;
        SubtraceTagsBase.commonTags.org_id = orgId;

        const _namespaces: Api.ListNamespaces_Item[] = await NamespaceUtils.getNamespaces(orgId, signal);
        setNamespaces(_namespaces);
        let currentNamespaceName: string | undefined = parseUrlRegex.exec(window.location.pathname)?.groups?.namespace;
        if (currentNamespaceName == null || !_namespaces.map((_namespace) => _namespace.name).includes(currentNamespaceName)) {
          currentNamespaceName = _namespaces[0].name;
        }
        const currentNamespace: Api.ListNamespaces_Item = ArrayUtils.findOrThrow(_namespaces, (_namespace) => _namespace.name === currentNamespaceName);
        setCurrentNamespace(currentNamespace);
        SubtraceTagsBase.commonTags.namespace_id = currentNamespace.namespaceId;
      }
    }

    initialize();
  }, [currentUser]);

  return (
    <React.StrictMode>
      <AppErrorBoundary>
        <DialogContext.Provider value={dialogManager}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route path="/dashboard" element={currentNamespace != null ? <Navigate to={`/dashboard/${currentNamespace.name}/requests`} /> : null} />
              <Route path="/dashboard/*" element={renderDashboardRoutes()} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
            {dialogState.isDialogOpen ? <Overlay className="absolute inset-0 w-dvw h-dvh flex items-center justify-center">{dialogState.dialogComponent}</Overlay> : null}
          </BrowserRouter>
        </DialogContext.Provider>
      </AppErrorBoundary>
    </React.StrictMode>
  );

  function renderDashboardRoutes(): React.ReactNode {
    if (!currentUser) {
      return null;
    }

    if (!currentUser.isLoggedIn) {
      return <Navigate to={`/login?next=${encodeURIComponent(window.location.pathname)}`} />;
    }

    if (!namespaces || !currentNamespace) {
      return null;
    }

    const setCurrentNamespaceAndUrl: (namespace: Api.ListNamespaces_Item) => void = (namespace) => {
      setCurrentNamespace(namespace);
      SubtraceTagsBase.commonTags.namespace_id = namespace.namespaceId;

      const matches: RegExpExecArray | null = parseUrlRegex.exec(window.location.pathname);
      const currentNamespaceName: string | undefined = matches?.groups?.namespace;
      const remainingPath: string | undefined = matches?.groups?.rest;
      if (currentNamespaceName != null && remainingPath != null) {
        // TODO(sachin): Avoid a full page reload when navigating if possible
        // https://github.com/subtrace/monorepo/issues/289
        window.location.pathname = `/dashboard/${namespace.name}/${remainingPath}`;
      }
    };

    const namespaceManager: NamespaceManager = {
      currentNamespace,
      setCurrentNamespace: setCurrentNamespaceAndUrl,
      namespaces,
      setNamespaces,
    };

    return (
      <OrgContext.Provider value={org}>
        <NamespaceContext.Provider value={namespaceManager}>
          <div className="w-screen h-screen max-w-[100vw] max-h-screen flex">
            <Navigation />
            <div className="basis-2/3 grow shrink flex overflow-auto">
              <Routes>
                <Route path="namespaces" element={<NamespacesPage />} />
                <Route path=":namespace_name">
                  <Route path="requests" element={<RequestsPage />} />
                  <Route path="tokens" element={<TokensPage />} />
                  <Route path="users" element={<UsersPage />} />
                </Route>
              </Routes>
            </div>
          </div>
        </NamespaceContext.Provider>
      </OrgContext.Provider>
    );
  }
}

type DialogState =
  | {
      isDialogOpen: false;
    }
  | {
      isDialogOpen: true;
      dialogComponent: React.ReactNode;
    };
